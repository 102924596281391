import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    ListSumUpReaders,
    ListSumUpReadersResponse,
    PairSumUpReader,
    PairSumUpReaderResponse,
    SumUpReader,
    SumUpResponse
} from "./types";

class SumUpApiClient {
    private client: AxiosInstance;

    constructor(bearerToken: string) {
        this.client = axios.create({
            baseURL: "https://api.sumup.com/",
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
            },
        });

        this.client.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error) => {
                console.error('API call error:', error.response?.data || error.message);
                return Promise.reject(error);
            }
        );
    }

    async listReaders(merchant: string): Promise<ListSumUpReadersResponse> {
        try {
            const response = await this.client.get<ListSumUpReaders>(`v0.1/merchants/${merchant}/readers`)
            return {
                success: true,
                message: '',
                data: response.data
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    success: false,
                    message: error.response?.data?.message || "Something went wrong",
                };
            } else {
                console.error("Unexpected error:", error);
                return {
                    success: false,
                    message: "An unexpected error occurred",
                };
            }
        }
    }

    async pairReader(merchant: string, data: PairSumUpReader): Promise<PairSumUpReaderResponse> {
        try {
            const response = await this.client.post<SumUpReader>(`v0.1/merchants/${merchant}/readers`, data)
            return {
                success: true,
                message: '',
                data: response.data
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    success: false,
                    message: error.response?.data?.message || "Something went wrong",
                };
            } else {
                console.error("Unexpected error:", error);
                return {
                    success: false,
                    message: "An unexpected error occurred",
                };
            }
        }
    }

    async unlinkReader(merchant: string, reader: string): Promise<SumUpResponse> {
        try {
            await this.client.delete<SumUpResponse>(`v0.1/merchants/${merchant}/readers/${reader}`)
            return {
                success: true,
                message: '',
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    success: false,
                    message: error.response?.data?.message || "Something went wrong",
                };
            } else {
                // Handle other types of errors
                console.error("Unexpected error:", error);
                return {
                    success: false,
                    message: "An unexpected error occurred",
                };
            }
        }
    }
}

export default SumUpApiClient;
