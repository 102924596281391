import React from "react";
import {Disclosure as HeadlessDisclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";

interface DisclosureProps {
    classList?: string
}

interface DisclosureButtonProps {
    children: React.ReactNode;
}

interface DisclosurePanelProps {
    children: React.ReactNode;
}

const DisclosureButton: React.FC<DisclosureButtonProps> = ({ children }) => {
    return null;
};

const DisclosurePanel: React.FC<DisclosurePanelProps> = ({ children }) => {
    return null;
};

const Disclosure:React.FC<DisclosureProps> & {
    Button: typeof DisclosureButton;
    Panel: typeof DisclosurePanel;
} = ({classList, children}) => {
    let buttonContent: React.ReactNode | undefined = undefined;;
    let panelContent: React.ReactNode | undefined = undefined;;

    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if (child.type === DisclosureButton) {
                buttonContent = child.props.children;
            } else if (child.type === DisclosurePanel) {
                panelContent = child.props.children;
            }
        }
    });

    // Validate that both button and panel are provided
    if (buttonContent === undefined) {
        throw new Error('Disclosure component requires a Disclosure.Button child');
    }

    if (panelContent === undefined) {
        throw new Error('Disclosure component requires a Disclosure.Panel child');
    }

    return (
        <div className={classList}>
            <HeadlessDisclosure>
                {({ open }) => (
                    <>
                        <HeadlessDisclosure.Button className="flex flex-row justify-between w-full p-4 border-b">
                            {buttonContent}
                            <ChevronDownIcon className={`-mr-1 ml-2 h-5 w-5 ${open ? 'rotate-180' : ''}`} />
                        </HeadlessDisclosure.Button>
                        <HeadlessDisclosure.Panel className="w-full p-4 border-x border-b">
                            {panelContent}
                        </HeadlessDisclosure.Panel>
                    </>
                )}
            </HeadlessDisclosure>
        </div>
    )
}

Disclosure.Button = DisclosureButton;
Disclosure.Panel = DisclosurePanel;

export default Disclosure;