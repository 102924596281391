import React, {useEffect, useState} from "react";
import Modal from "../../../components/Modal/Modal";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import SumUpApiClient from "../../../plugins/sumup/client";
import {TableData, TableHeader} from "../../../components/Table/Table";
import {TrashIcon} from "@heroicons/react/outline";
import {
  ListSumUpReaders,
  ListSumUpReadersResponse,
  PairSumUpReader,
  PairSumUpReaderResponse,
  SumUpReader, SumUpReaderStatus
} from "../../../plugins/sumup/types";
import Input from "../../../components/Form/Input";
import Heading, {Level} from "../../../components/Typography/Heading";

interface SumupModalProps {
  apiKey: string;
  merchantCode: string;
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SumupModal: React.FC<SumupModalProps> = ({apiKey, merchantCode, isOpen, setIsOpen}) => {

  const [sumUpClient, setSumUpClient] = useState<SumUpApiClient | null>(null);

  const [readerList, setReaderList] = useState<SumUpReader[]>([]);

  const [newReaderName, setNewReaderName] = useState<string>("");
  const [newReaderPairingCode, setNewReaderPairingCode] = useState<string>("");

  const [pairingError, setPairingError] = useState<string>("");

  useEffect(() => {
    setSumUpClient(new SumUpApiClient(apiKey))
    if (merchantCode !== "") {
      getReaderList().then();
    }
  }, [apiKey, isOpen]);

  useEffect(() => {
    if (pairingError !== "") {
      setPairingError("");
    }
  }, [newReaderName, newReaderPairingCode]);

  const getReaderList = async () => {
    if (sumUpClient) {
      const response: ListSumUpReadersResponse = await sumUpClient.listReaders(merchantCode)
      if (response.success && response.data) {
        setReaderList(response.data.items)
      }
    }
  }

  const unpairReader = async (id: string) => {
    if (sumUpClient){
      const response = await sumUpClient.unlinkReader(merchantCode, id)
      if (response.success) {
        getReaderList().then()
      }
     }
  }

  const pairReader = async () => {
    if (sumUpClient) {
      const requestData: PairSumUpReader = {
        name: newReaderName,
        pairing_code: newReaderPairingCode,
      }

      const response: PairSumUpReaderResponse = await sumUpClient.pairReader(merchantCode, requestData)
      if (response.success) {
        setNewReaderName("");
        setNewReaderPairingCode("");
        getReaderList().then()
      } else if (!response.success) {
        setPairingError(response.message)
      }
    }
  }

  const getStatusString = (status: SumUpReaderStatus): string => {
    switch (status) {
      case "processing":
        return "Connectie verwerken"
      case "paired":
        return "Aangesloten"
      case "expired":
        return "Verlopen"
      default:
        return "Onbekend"
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Modal.Body>
          <div className="mb-4">
            <div className="flex flex-row justify-between items-center mb-4">
              <Heading level={Level.HEADING_3}>SumUp Readers</Heading>
              <Button
                  type="button"
                  onClick={async () => {
                    await getReaderList()
                  }}
              >
                Verversen
              </Button>
            </div>
            {readerList.length > 0 ? <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <TableHeader>Naam</TableHeader>
                  <TableHeader>Id</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader><span className="sr-only">Verwijderen</span></TableHeader>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {readerList.map((r) => (
                <tr key={r.id} className="hover:backdrop-brightness-95">
                  <TableData>{r.name}</TableData>
                  <TableData>{r.device.identifier}</TableData>
                  <TableData>{getStatusString(r.status)}</TableData>
                  <TableData classList="text-right text-red-500 hover:text-red-800 cursor-pointer">
                    <TrashIcon
                        onClick={async () => {
                          await unpairReader(r.id)
                        }}
                        className="h-6 w-6"
                    />
                  </TableData>
                </tr>
              ))}
              </tbody>
            </table> : <div>Geen readers gevonden</div>}
          </div>

          <div className="mt-5">
            <Heading level={Level.HEADING_3} classList={"pb-5"}>Nieuwe reader toevoegen</Heading>
            <Input
                classList="pb-5"
                label="Naam"
                id="new-reader-name"
                name="new-reader-name"
                type="text"
                defaultValue={newReaderName}
                value={newReaderName}
                onChange={(e) => {
                  setNewReaderName(e.target.value);
                }}
            />
            <Input
                classList="pb-5"
                label="Koppel code"
                id="new-pairing-code"
                name="new-pairing-code"
                type="text"
                defaultValue={newReaderPairingCode}
                value={newReaderPairingCode}
                onChange={(e) => {
                  setNewReaderPairingCode(e.target.value);
                }}
            />

            {pairingError !== "" && (
              <div className="text-red-500 mb-2">
                {pairingError}
              </div>
            )}

            <Button
                type="button"
                buttonStyle={ButtonStyle.NEW}
                disabled={newReaderName.length === 0 && newReaderPairingCode.length === 0}
                onClick={async () => {
                  await pairReader()
                }}
            >
              Koppelen
            </Button>
          </div>

          <div className="w-full flex flex-col md:flex-row-reverse md:justify-between ">
            <Button
                type="button"
                buttonStyle={ButtonStyle.CANCEL}
                onClick={() => setIsOpen(false)}
            >
              Sluiten
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SumupModal