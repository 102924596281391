import React, {useContext, useEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'
import {AuthContext} from "../../../providers/AuthProvider";
import {NavLink} from "react-router-dom";
import {ProductCount} from "../../../plugins/middleware-api-client";
import {render} from "@testing-library/react";

interface PaginationProps {
  page: number
  productCount: ProductCount | null,
  hasNextPage: boolean,
  onPageChange: (page: number) => void
  isLoading: boolean
}

const Pagination:React.FC<PaginationProps> = ({page, productCount, hasNextPage, onPageChange, isLoading}) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [productsPerPage, setProductsPerPage] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [productsForPage, setProductsForPage] = useState<number>(0)

  useEffect(() => {
    if (productCount) {
      setTotalPages(productCount?.totalPages ?? 0)
      setProductsPerPage(productCount?.productsPerPage ?? 0)
      setTotalProducts(productCount?.productsTotal ?? 0)

      setProductsForPage(page * productsPerPage < totalProducts ? page * productsPerPage : totalProducts)
    }
  }, [productCount]);

  // Generate the page numbers to display with truncation
  const getPageNumbers = () => {
    const MAX_VISIBLE_PAGES = 10;
    const pages = [];
    const alteredTotalPages = totalPages + 1

    // If we have 10 or fewer pages, show all of them
    if (totalPages <= MAX_VISIBLE_PAGES) {
      for (let i = 1; i <= alteredTotalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always include first and last page
      // For current page, show 2 pages before and after when possible

      // Add first page
      pages.push(1);

      // Calculate start and end of visible range around current page
      let rangeStart = Math.max(2, page - 2);
      let rangeEnd = Math.min(alteredTotalPages - 1, page + 2);

      // Adjust range to show up to 7 pages (1, ..., 5 pages, ..., last)
      const MAX_MIDDLE_PAGES = 5;
      if (rangeEnd - rangeStart + 1 < MAX_MIDDLE_PAGES) {
        if (rangeStart === 2) {
          rangeEnd = Math.min(totalPages - 1, rangeStart + MAX_MIDDLE_PAGES - 1);
        } else if (rangeEnd === alteredTotalPages - 1) {
          rangeStart = Math.max(2, rangeEnd - MAX_MIDDLE_PAGES + 1);
        }
      }

      // Add ellipsis if needed before range
      if (rangeStart > 2) {
        pages.push("ellipsis-start");
      } else if (rangeStart === 2) {
        pages.push(2);
      }

      // Add pages in the middle range
      for (let i = rangeStart + 1; i < rangeEnd; i++) {
        pages.push(i);
      }

      // Add ellipsis if needed after range
      if (rangeEnd < alteredTotalPages - 1) {
        pages.push("ellipsis-end");
      } else if (rangeEnd === alteredTotalPages - 1) {
        pages.push(alteredTotalPages - 1);
      }

      // Add last page
      pages.push(alteredTotalPages);
    }

    return pages;
  };

  const renderPageButton = (pageNum: number | string, index: number) => {
    if (pageNum === "ellipsis-start" || pageNum === "ellipsis-end") {
      return (
          <span key={`${pageNum}-${index}`} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
          ...
        </span>
      );
    }

    return (
        <button
            key={pageNum}
            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                pageNum === page ? 'z-10 bg-default border-secondary text-secondary-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
            }`}
            onClick={() => {onPageChange(pageNum as number)}}
        >
          {pageNum}
        </button>
    );
  };

  return (
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          {page > 1 &&
              <button
                  onClick={() => {onPageChange(page - 1)}}
                  disabled={isLoading}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </button>
          }
          {hasNextPage &&
              <button
                  onClick={() => {onPageChange(page + 1)}}
                  disabled={isLoading}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </button>
          }
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {isLoading ? (
                <p className="text-sm text-gray-700">
                  <span className="font-medium">...</span> tot <span className="font-medium">...</span> van de{' '}
                  <span className="font-medium">...</span> resultaten
                </p>
            ) : (
                <p className="text-sm text-gray-700">
                  <span className="font-medium">{page * productsPerPage - productsPerPage + 1}</span> tot <span className="font-medium">{productsForPage}</span> van de{' '}
                  <span className="font-medium">{totalProducts}</span> resultaten
                </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              {(page > 1 && !isLoading) &&
                  <button
                      onClick={() => {onPageChange(page - 1)}}
                      disabled={isLoading}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
              }

              {isLoading ? (
                <>
                  {renderPageButton(1, 1)}
                </>
              ) : (
                <>
                  {getPageNumbers().map((pageNum, index) => renderPageButton(pageNum, index))}
                </>
              )}

              {(hasNextPage && !isLoading) &&
                  <button
                      onClick={() => {onPageChange(page + 1)}}
                      disabled={isLoading}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
              }
            </nav>
          </div>
        </div>
      </div>
  )
}

export default Pagination