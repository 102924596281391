import React, {FormEvent, useContext, useEffect, useState} from "react";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import {useCSVReader} from "react-papaparse";
import {AuthContext} from "../../../providers/AuthProvider";
import {toast} from "react-toastify";

interface Coupon {
    coupon_code: string
    amount: number
}

const UploadCoupon: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { CSVReader } = useCSVReader();

    const [buttonLook, setButtonLook] = useState<string>(ButtonStyle.NEW);
    const [buttonText, setButtonText] = useState<string | undefined>(undefined);
    const [csvFile, setCsvFile] = useState<[]>([]);
    const [csvFileName, setCsvFileName] = useState<string>("");
    const [formattedFileName, setFormattedFileName] = useState<string>("");

    useEffect(() => {
        setFormattedFileName(generateCouponFilename(csvFileName))
    }, [csvFileName]);

    const updateButton = (style: ButtonStyle, buttonText: string) => {
        setButtonLook(style);
        setButtonText(buttonText);
    };

    const generateCouponFilename = (name: string): string => {
        const today = new Date();

        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = today.getFullYear();

        const fileDate = `${day}${month}${year}`

        if (name == "") {
            return `coupons_${fileDate}.csv`;
        }

        return `coupons_${fileDate}_${name}.csv`;
    }

    const handleCsvFile = (csvFile: [], file: string) => {
        setCsvFile(csvFile);
        try {
            updateButton(ButtonStyle.SAVE, file);
        } catch (e) {
            updateButton(ButtonStyle.ERROR, "Fout gevonden in het csv bestand");
        }
    };

    const handleSubmit = async () => {
        let couponData: Coupon[] = csvFile.map((row) => {
            return {
                coupon_code: row["Code"],
                amount: parseInt(row["Waarde"])
            }
        })

        const headers = Object.keys(couponData[0]);

        const csvRows = [];

        csvRows.push(headers.join(';'));

        for (const row of couponData) {
            const values = headers.map(header => {
                const value = row[header as keyof Coupon];
                return String(value);
            });
            csvRows.push(values.join(';'));
        }

        const csvString = csvRows.join('\n');

        const success = await authContext.uploadCouponCsvFile(csvString, formattedFileName)
        if (success) {
            toast.success("Coupon codes geupload")
        } else {
            toast.error("Fout tijdens het uploaden van de coupon codes");
        }
    }

    return (
        <div>
            <form
                onSubmit={async (e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    await handleSubmit();
                }}
                className="space-y-4"
            >
                <div className="border-b">
                    <label
                        htmlFor="uploadButton"
                        className="block text-sm font-medium text-secondary sm:mt-px sm:p-2 after:content-['*'] after:text-red-500"
                    >
                        Selecteer Bestand
                    </label>
                    <CSVReader
                        onUploadAccepted={(results: any, file: File) => {
                            if (results.data.length < 1) {
                                updateButton(ButtonStyle.ERROR, "Geen data gevonden in csv bestand");
                                return;
                            }

                            if (
                                !results.data[0].hasOwnProperty("Code") ||
                                !results.data[0].hasOwnProperty("Waarde")
                            ) {
                                updateButton(ButtonStyle.ERROR, "Csv bestand heeft niet de juiste kolommen (Code en Waarde)");
                                return;
                            }
                            updateButton(ButtonStyle.CANCEL, "");
                            handleCsvFile(results.data, file.name);
                        }}
                        config={{
                            header: true,
                        }}
                    >
                        {({ getRootProps, acceptedFile }: any) => (
                            <>
                                <Button
                                    type="button"
                                    buttonStyle={buttonLook}
                                    {...getRootProps()}
                                    id="uploadButton"
                                >
                                    {(buttonText && buttonText) || "Upload"}
                                </Button>
                            </>
                        )}
                    </CSVReader>
                </div>

                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label htmlFor="csvFileName"
                           className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                        Bestandsnaam toevoeging (optioneel)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="csvFileName"
                            id="csvFileName"
                            step="any"
                            className={`focus:ring-secondary focus:border-secondary block w-full pr-12 sm:text-sm border-gray-300 rounded-md`}
                            onChange={(e) => {
                                setCsvFileName(e.target.value);
                            }}
                            defaultValue={csvFileName}
                        />
                    </div>
                </div>

                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                        Bestandsnaam
                    </label>
                    <div className="mt-1 relative rounded-md">
                        {formattedFileName}
                    </div>
                </div>

                <Button
                    disabled={csvFile.length === 0}
                    buttonStyle={
                        csvFile.length === 0
                            ? ButtonStyle.ERROR
                            : ButtonStyle.SAVE
                    }
                >
                    Toevoegen
                </Button>
            </form>
        </div>
    )
}

export default UploadCoupon;