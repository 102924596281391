import React, {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import Panel from "../../components/Panel/Panel";
import Heading, {Level} from "../../components/Typography/Heading";
import Text from "../../components/Typography/Text";
import Disclosure from "../../components/Disclosure/Disclosure";
import UploadCoupon from "./components/UploadCoupon";

const Admin: React.FC = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate()

    return (
        <div>
            <Panel>
                <div className="mb-6">
                    <Heading>Admin</Heading>
                    <Text>Functionaliteiten voor admin</Text>
                </div>

                <Disclosure>
                    <Disclosure.Button>
                        <Text>Coupons uploaden</Text>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                        <UploadCoupon />
                    </Disclosure.Panel>
                </Disclosure>
            </Panel>
        </div>
    )
}

export default Admin;
