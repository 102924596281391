import React, { useState, useEffect } from 'react';

interface LoadingSpinnerProps {
    text?: string | undefined;
}

const LoadingSpinner:React.FC<LoadingSpinnerProps> = ({text}) => {
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRotation(prevRotation => (prevRotation + 15) % 360);
        }, 50);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center p-8">
            <div className="relative h-16 w-16">
                <div
                    className="absolute inset-0 border-4 border-gray-200 border-t-secondary rounded-full"
                    style={{ transform: `rotate(${rotation}deg)` }}
                />
            </div>
            <p className="mt-4 text-gray-700 font-medium">{text || "Laden..."}</p>
        </div>
    );
};

export default LoadingSpinner;